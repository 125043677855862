







































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { ProgrammaticVoucher as ProgrammaticVoucherEntity } from '@/domain/entities/ProgrammaticVoucher'
import controller from '@/app/ui/controllers/ProgrammaticVoucherController'
import ProgrammaticVoucherItem from '../components/ProgrammaticVoucherItem/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import { Utils } from '@/app/infrastructures/misc'
import { PROGRAMMATIC_VOUCHER_PAGINATION } from '@/app/infrastructures/misc/Constants'
import * as constantData from '@/app/infrastructures/misc/Constants/programaticVoucher'
import { Dictionary } from 'vue-router/types/router'

@Component({
  components: {
    ProgrammaticVoucherItem,
    Button,
    TextInput,
    DropdownSelect,
    PaginationNav,
    Loading,
    Modal,
    EmptyState,
  },
})
export default class ProgrammaticVoucher extends Vue {
  isGuest = Utils.isGuest()
  selectedItem = new ProgrammaticVoucherEntity()
  confirmationModal = false
  successModal = false
  controller = controller
  filterOptions = [
    { label: 'Show All', value: 'SHOW_ALL' },
    { label: 'Published', value: 'PUBLISHED' },
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Pending', value: 'PENDING' },
    { label: 'Cancelled', value: 'CANCELLED' },
    { label: 'Expired', value: 'EXPIRED' },
  ]
  sortOptions = [
    { label: 'Newest', value: 'NEWEST' },
    { label: 'Oldest', value: 'OLDEST' },
  ]
  parameters = {
    page: 1,
    perPage: PROGRAMMATIC_VOUCHER_PAGINATION,
    keyword: null,
    sortBy: this.sortOptions[0],
    filterBy: this.filterOptions[0],
  }

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage:
          Utils.alwaysNumber(queries.perPage) ||
          PROGRAMMATIC_VOUCHER_PAGINATION,
        keyword: queries.keyword,
        sortBy:
          this.sortOptions.find(item => item.value === queries.sortBy) ||
          this.sortOptions[0],
        filterBy:
          this.filterOptions.find(item => item.value === queries.filterBy) ||
          this.filterOptions[0],
      }
    }

    this.fetchProgrammaticVoucher()
  }

  get params(): Record<string, unknown> {
    return {
      ...this.parameters,
      sortBy: this.parameters.sortBy.value,
      filterBy: this.parameters.filterBy.value,
    }
  }

  get voucherData(): ProgrammaticVoucherEntity[] {
    return controller.voucherData.map(v => {
      v.voucherTarget = constantData.TARGET_USERS.find(
        item => item.value === v.voucherTarget
      )?.label
      v.voucherPurposeChild = constantData.TERM_COND_OPTIONS.find(
        item => item.value === v.voucherPurposeChild
      )?.label

      return v
    })
  }

  @Watch('parameters.keyword')
  onKeywordChanged(val: string): void {
    val === '' && this.fetchProgrammaticVoucher(true)
  }

  @Watch('params')
  onParamsChanged(
    val: Dictionary<string | (string | null)[] | null | undefined> | undefined
  ): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  private cancelVoucher(): void {
    controller.deleteVoucher({
      voucherId: `${this.selectedItem.id}`,
    })
    this.confirmationModal = false
  }

  @Watch('controller.isSuccessCancelVoucher')
  onWatchCancelVoucher(isSuccess: boolean): void {
    if (isSuccess) {
      this.successModal = true
    }
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.fetchProgrammaticVoucher(true)
  }

  private fetchProgrammaticVoucher(resetPage?: boolean): void {
    if (resetPage) {
      this.parameters.page = 1
    }
    controller.getProgrammaticVoucherList(this.params)
  }
}
